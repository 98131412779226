import { getChaveAtivacaoRevenda, getDevice, getEnvironment, getFrontUrl, getGatewayVersion, getIsDev, getLabel, getPerformanceDebugMode } from './environment-variables';
import packageJson from './version';
import { IVariaveisAmbiente } from './types';

const environment = getEnvironment();

const apiUrlNew = `https://api-gateway-${environment}.touchone.com.br`;
const apiNcmUrlNew = `https://api-ncms-${environment}.touchone.com.br`;
const apiCarga = `https://api-conversoes-${environment}.touchone.com.br`;
const apiSetoresURL = `https://api-setores-${environment}.touchone.com.br`;
const apiPedidosURL = `https://api-pedidos-${environment}.touchone.com.br`;
const apiLocalizacaoURL = `https://api-localizacao-${environment}.touchone.com.br`;
const apiCardapioURL = `https://api-cardapio-${environment}.touchone.com.br`;
const apiSNGPCURL = `https://api-sngpc-${environment}.touchone.com.br`;
const apiRelatoriosURL = `https://api-relatorios-staging.touchone.com.br`;

export const chaveRevendaSafra = ``;
export const sistemaId = `bd2d7c0c-1383-444f-8ddb-70047aade745`
export const versionJson = packageJson.version as string;

const _variaveisAmbiente: IVariaveisAmbiente = {
  //REDES SOCIAIS (DESABILITADO POR CONTA DE WHITELABELS)
  facebookAppId: '661218711269424',
  googleAppId: "2727919z1635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com",
  googleMapsKey: `AIzaSyCDoIa4tkrK_R2B7br7AqiVKk7eYEBdjoo`,

  apiSaurusMaster: "https://wsmaster.saurus.net.br/v004/serviceMaster.asmx",
  apiImagemUrl: `https://api-imagens.saurus.net.br`,
  apiTributarioUrl: `https://api-tributario.retaguarda.app`,
  apiUrlConvenio: `https://api-crediario-staging.touchone.com.br`,

  paymentDevice: getDevice(),
  frontUrl: getFrontUrl(),

  performanceDebugMode: getPerformanceDebugMode(),
  isDev: getIsDev(),

  chaveAtivacaoRevenda: getChaveAtivacaoRevenda(),
  environment: environment,
  label: getLabel(),

  version: versionJson,

  //VERSAO DO GATEWAY
  gatewayVersion: getGatewayVersion(),

  //URLS VARIAVEIS
  apiUrl: apiUrlNew,
  apiUrlNcms: apiNcmUrlNew,
  apiUrlCarga: apiCarga,
  apiUrlSNGPC: apiSNGPCURL,
  apiUrlSetores: apiSetoresURL,
  apiUrlPedidos: apiPedidosURL,
  apiUrlCardapio: apiCardapioURL,
  apiUrlLocalizacao: apiLocalizacaoURL,
  apiRelatorios: apiRelatoriosURL
};

export const VariaveisAmbiente = _variaveisAmbiente;
